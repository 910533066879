<template>
  <div>

    <HeaderMain />

    <!-- КОНТАКТЫ И РЕКВИЗИТЫ -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="
        grid-column-gap: unset;
        position: relative;
        overflow: hidden;
        background-color: #F3F3F3;">


          <!-- Картинка в углу -->
          <img src="../../../../../public/images/map-dot.png" alt="map dot"
               style="position: absolute;
                 bottom: 0;
                 right: 0;
                 left: 0;
                 margin: 0 auto;
                 max-width: 90%;
                 max-height: 80%;"
          >

          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Контакты<br>и реквизиты</h1>
              </div>
              <div class="w-layout-vflex flex-good direction-adaptive"
                   style="flex-flow: row; align-items: center;"
              >
                <a @click="goToWriteToUs" class="button-bust w-button blue-button">Связаться</a>
<!--                <div class="w-layout-vflex flex-good"-->
<!--                     style="flex-flow: row; align-items: center;">-->
<!--                  <img style="height: 100% !important;" src="../../../../../public/images/telegram-blue.svg" alt="telegram">-->
<!--                  <h1 class="text-mini white">Подпишись и будь в курсе<br>всех событий и акций</h1>-->
<!--                </div>-->
              </div>
            </div>
          </div>


          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               border-radius: unset;
               padding: 25px;
               padding-bottom: 35px;
               align-items: end;
            ">

            <div class="w-layout-vflex item _1 w-100" style="max-width: 400px;">
              <div>
                <div class="text-mini semibold" style="margin-bottom: 20px;">Контакты</div>

                <p class="plane-text">Поддержка клиентов — бесплатно, круглосуточно</p>

                <h1 class="heading small">support@brokton-zaim.ru</h1>
                <p class="plane-text">Официальная почта. Ответ предоставляется в течение рабочего дня с Пн.-Пт.</p>

                <p class="plane-text">Юридический адрес 420103, Республика Татарстан (Татарстан), г. Казань, г.о. город Казань, пр-кт Ямашева, д. 54, к. 3, кв. 47</p>
                <p class="plane-text">Номер свидетельства 2303392009993 -
                  <a href="https://www.cbr.ru/">cbr.ru</a></p>
              </div>

              <div>
                <div class="text-mini semibold" style="margin-bottom: 20px;">Банковские реквизиты</div>
                <div class="flex-table">
                  <div class="row text-mini white">ИНН</div>
                  <div class="row text-mini white">1685013200</div>

                  <div class="row text-mini white">ОГРН</div>
                  <div class="row text-mini white">1231600043619</div>

                  <div class="row text-mini white">КПП</div>
                  <div class="row text-mini white">168501001</div>

                  <div class="row text-mini white">ОКПО</div>
                  <div class="row text-mini white">48201506</div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Броктон В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Броктон в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Main',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderMain
  },
  created() {
  },
  computed: {
  },
  methods: {
    goToDocument(name_doc) {
      this.$router.push('/documents/' + name_doc);
    },
    goToWriteToUs() {
      this.$router.push('/write-to-us');
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    }
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
.flex-table {
  display: flex;
  flex-wrap: wrap; /* Чтобы строки занимали свои места */
}

.row {
  width: 50%;
  padding: 5px 0; /* Отступы между строками */
}

.flex-table .row:nth-child(odd) {
  text-align: left;
}

.flex-table .row:nth-child(even) {
  text-align: right;
}

.row.text-mini.white {
  line-height: unset;
}
</style>
